import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { HeadingTicker } from "../components/HeadingTicker"
import Slices from "../components/Slices"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../../linkResolver"

const Page = ({ data }: { data: any }) => {
  return (
    <Layout>
      <HeadingTicker>{data.prismicPage.data.title.text}</HeadingTicker>
      <div className={`w-full ${data.prismicPage.uid}`}>
        <Slices slices={data.prismicPage.data.body} />
      </div>
    </Layout>
  )
}

export default withPrismicPreview(Page, [
  { repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME ?? "", linkResolver },
])

export const query = graphql`
  query Page($id: String) {
    prismicPage(id: { eq: $id }) {
      _previewable
      id
      uid

      data {
        body {
          ... on PrismicPageDataBodyParagraph {
            id
            slice_type
            primary {
              content {
                richText
                html
                raw
              }
              size
            }
          }
          ... on PrismicPageDataBodyContactForm {
            id
            slice_type
          }
        }
        title {
          text
        }
      }
    }
  }
`

import React from "react"
import ReactCurvedText from "react-curved-text"

export const AshCircle = () => {
  return (
    <svg viewBox="0 0 100 100" width="100" height="100" className="ash-circle">
      <defs>
        <path
          id="circle"
          d="
        M 50, 50
        m -37, 0
        a 37,37 0 1,1 74,0
        a 37,37 0 1,1 -74,0"
        />
      </defs>
      <text fontSize="16" fontFamily="Ogg">
        <textPath xlinkHref="#circle">Ash North Compton</textPath>
      </text>
    </svg>
  )
}

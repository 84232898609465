/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import SlideMenu from "./SlideMenu"
import { Footer } from "./footer"
import { motion } from "framer-motion"
type LayoutProps = {
  children: any
  className?: string
}

const Layout = ({ children, className }: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      className={`wrapper bg-no-repeat bg-center bg-opacity-25 ${
        className || ""
      }`}
    >
      <SlideMenu />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <motion.main
        className="md:pt-20"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={{
          initial: { opacity: 0 },
          animate: { opacity: 1 },
          exit: { opacity: 0, transition: { duration: 0.5 } },
        }}
      >
        {children}
      </motion.main>
      <Footer />
    </div>
  )
}

export default Layout

import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { HeadingTicker } from "../components/HeadingTicker"
import Slices from "../components/Slices"
import { CategoryIcon } from "../components/CategoryIcon"

const Project = ({
  data: { prismicProject },
}: {
  data: {
    prismicProject: any
  }
}) => {
  const category = prismicProject.data.category
  const title = category.document.data.title.text
  const data = prismicProject.data

  return (
    <Layout className="relative">
      <Seo title={prismicProject.data.title.text} />
      <HeadingTicker>{title}</HeadingTicker>
      <div className="relative mx-auto max-w-5xl">
        <div className="px-4 md:px-8 mt-10 md:mt-20">
          <div className="flex flex-col gap-y-5 md:gap-y-5 items-center justify-center">
            <CategoryIcon
              categoryUid={category.uid}
              fill="black"
              style={{ width: "15rem", height: "5rem" }}
            />
            <h1 className="font-ogg text-3xl md:text-5xl text-center max-w-2xl">
              {prismicProject.data.title.text}
            </h1>

            <div className="content h-full mt-8">
              {data.excerpt.html && (
                <div
                  className="text-lg md:text-2xl text-center transition-all overflow-scroll my-10 max-w-5xl mx-auto"
                  dangerouslySetInnerHTML={{ __html: data.excerpt.html }}
                />
              )}

              {data.body1 && (
                <div className="my-10">
                  <Slices slices={data.body1} />
                </div>
              )}

              <footer className="flex items-center justify-center pb-10 px-4 md:px-8">
                <small className="text-center">
                  {category.uid === "sustainable-interior-architecture"
                    ? "All design projects courtesy Perkins & Will and Photography by Eric Laignel"
                    : "Works and projects copyright 2022 Ash Compton."}
                </small>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Project($id: String) {
    prismicProject(id: { eq: $id }) {
      _previewable
      id
      data {
        title {
          text
        }
        body {
          html
        }
        body1 {
          ... on PrismicProjectDataBody1Image {
            id
            slice_type
            primary {
              image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicProjectDataBody1Paragraph {
            id
            slice_type
            primary {
              content {
                html
                raw
              }
              size
            }
          }
        }

        excerpt {
          text
          html
        }
        category {
          uid
          document {
            ... on PrismicProjectCategory {
              id
              uid
              data {
                title {
                  text
                }
              }
            }
          }
        }
        color
      }
    }
  }
`

export default Project

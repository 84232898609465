import React, { CSSProperties } from "react"

export const Arch = ({
  fill = "white",
  style,
}: {
  fill: string
  style?: CSSProperties
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 166 59"
    style={style}
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M91.7,29.3c1.4,1.3,2.8,2.6,4.1,3.9c2.3,2.3,4.7,4.6,7,7c0.9,0.9,1,1.4,0,2.4c-2.2,2.2-4.4,4.5-6.7,6.7
		c-1.4,1.4-1.6,1.4-3-0.1c-3.3-3.3-6.6-6.6-9.9-9.9c-0.2-0.2-0.5-0.5-0.8-0.8c-0.2,0.2-0.5,0.3-0.7,0.5c-2.8,2.8-5.5,5.5-8.3,8.3
		c-0.4,0.4-1,0.7-1.6,0.9c-2.4,0.7-4.9,1.3-7.3,1.9c-0.5,0.1-1.3,0.2-1.6-0.1c-0.3-0.3-0.3-1.1-0.1-1.6c0.7-2.5,1.5-4.9,2.3-7.4
		c0.2-0.6,0.7-1.1,1.2-1.6c2.6-2.6,5.2-5.2,7.9-7.9c0.2-0.2,0.4-0.4,0.6-0.7c-0.3-0.3-0.6-0.6-0.8-0.9c-3.5-3.5-7.1-7.1-10.6-10.6
		c-1.2-1.2-1.1-1.4,0.1-2.6c2.3-2.3,4.5-4.6,6.8-6.8c0.9-0.9,1.4-0.9,2.3,0c3.6,3.6,7.3,7.2,10.8,10.8c0.7,0.7,1.1,0.7,1.8,0
		c3.5-3.6,7.1-7.1,10.6-10.6c1.2-1.2,1.5-1.1,2.7,0c1.7,1.7,3.3,3.3,5,5c1.1,1.1,1.1,1.4,0,2.6c-3.5,3.5-7,7-10.5,10.5
		C92.5,28.3,92.2,28.7,91.7,29.3z M93.1,15.5c-1.3,1.3-2.5,2.6-3.7,3.8c-7,7-13.9,13.9-20.9,20.9c-0.6,0.6-0.6,1,0,1.5
		c1,0.9,2,1.9,3,3c0.6,0.7,1,0.6,1.6,0c7.4-7.4,14.8-14.8,22.2-22.2c0.8-0.8,1.6-1.6,2.4-2.5C96.1,18.5,94.7,17.1,93.1,15.5z
		 M78.3,19c-1.9-2.5-1.9-2.5-3.7-0.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.5-1.1,0.6-1.7,0.1c-0.5-0.5-0.3-1.1,0.1-1.6
		c0.4-0.5,0.9-0.9,1.3-1.3c0.4-0.4,0.5-0.7,0-1.1c-0.8-0.7-1.5-1.5-2.2-2.2c-0.4-0.4-0.7-0.5-1.1,0c-1.7,1.7-3.4,3.4-5.1,5.1
		c-0.5,0.5-0.4,0.8,0,1.2c3.4,3.4,6.8,6.8,10.2,10.1c0.4,0.4,0.7,0.4,1.1,0c0.8-0.9,1.7-1.7,2.6-2.6c1-1,2.1-1.9,3.5-3.2
		c-1.1-0.9-1.9-1.5-2.6-2.2c-0.5-0.5-0.9-0.4-1.3,0.1c-0.4,0.4-0.8,0.8-1.2,1.2c-0.5,0.5-1.1,0.7-1.7,0.1c-0.6-0.6-0.4-1.2,0.1-1.6
		C76.9,20,77.6,19.5,78.3,19z M94.5,47.7c1.7-1.7,3.2-3.2,4.7-4.7c1.7-1.6,1.7-1.7,0-3.3c-1.6-1.6-1.6-1.6-3.4,0
		c-0.5,0.5-1.1,1-1.8,0.4c-0.7-0.7-0.3-1.3,0.3-1.9c1.7-1.8,1.7-1.8-0.2-3.5c0,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.2-0.2-0.2
		c-0.6,0.7-1.1,1.4-1.7,2c-0.5,0.5-1,0.6-1.6,0.1c-0.5-0.5-0.4-1.1,0-1.6c0.1-0.1,0.2-0.3,0.4-0.4c1.6-1.6,1.6-1.6-0.1-3.3
		c-0.5-0.5-0.8-0.4-1.2,0c-1.6,1.7-3.3,3.3-5,5c-0.6,0.6-0.5,0.9,0.1,1.4c1.9,1.9,3.9,3.8,5.8,5.8C91.8,44.8,93.1,46.2,94.5,47.7z
		 M101.3,16.3c0.1-0.1,0.1-0.2,0.2-0.3c-0.9-0.9-1.9-1.7-2.8-2.6c-2.1-2.1-1.3-1.8-3.4,0.1c-0.4,0.4-0.3,0.7,0,1
		c0.7,0.7,1.4,1.4,2.2,2.1C99.5,18.7,99.5,18.7,101.3,16.3C101.3,16.3,101.3,16.3,101.3,16.3z M65.3,47.4c0.1,0.1,0.2,0.2,0.2,0.2
		c1.4-0.4,2.9-0.8,4.1-1.1c-1.1-1.1-2-2.1-3.1-3.2C66.1,44.7,65.7,46,65.3,47.4z"
        fill={fill}
      />
      <path
        d="M161.8,22c-0.1,0.2-0.1,0.4-0.2,0.5c-0.8,1.4-0.8,1.4-2.2,0.5c-0.7-0.5-1.4-1-2.3-1.5c0,0.7-0.1,1.2-0.1,1.7
		c0,8.4,0,16.8,0,25.2c0,0.3,0,0.6,0,0.9c0,1.3-0.3,1.6-1.6,1.6c-3.3,0-6.7,0-10,0c-6.2,0-12.3,0-18.5,0c-1.8,0-2-0.3-2-2.1
		c0-8.5,0-16.9,0-25.4c0-0.6,0-1.1,0-2c-1.1,0.7-2,1.2-2.9,1.9c-0.8,0.6-1.1,0.2-1.5-0.4c-0.4-0.6-0.4-1,0.2-1.4
		c1.7-1.1,3.3-2.2,5-3.3c0.7-0.4,1-1,1-1.8c0-1.7,0-3.4,0-5.1c0-1.3,0.3-1.6,1.6-1.6c1.5,0,3,0,4.5,0c1.4,0,1.6,0.2,1.7,1.6
		c0,0.3,0.1,0.5,0.2,1c0.9-0.5,1.6-1,2.3-1.5c1-0.6,2-1.2,2.9-1.9c0.8-0.6,1.4-0.5,2.2,0c2.6,1.8,5.3,3.5,8,5.3
		c3.6,2.4,7.2,4.8,10.8,7.2C161.1,21.5,161.4,21.8,161.8,22z M127,34.2C127,34.2,127,34.2,127,34.2c0,4.5,0,8.9,0,13.4
		c0,0.8,0.2,1.1,1.1,1.1c1.6-0.1,3.3-0.1,4.9,0c1.1,0.1,1.3-0.3,1.3-1.3c0-5.1,0-10.2,0-15.3c0-1.5,0.2-1.7,1.7-1.7
		c3.2,0,6.4,0,9.6,0c1.7,0,1.9,0.2,1.9,1.9c0,5.1,0,10.2,0,15.3c0,0.9,0.2,1.2,1.1,1.1c1.8-0.1,3.6,0,5.4,0c0.7,0,0.9-0.2,0.9-0.9
		c0-9,0-18,0-27.1c0-0.4-0.3-1-0.6-1.2c-4.2-2.8-8.4-5.6-12.7-8.4c-0.3-0.2-1-0.1-1.3,0.1c-4.1,2.7-8.2,5.4-12.3,8.1
		c-0.6,0.4-0.9,0.9-0.9,1.6C127,25.4,127,29.8,127,34.2z M145.3,40.6c0-2.3,0-4.7,0-7c0-0.7-0.2-1.1-1-1.1c-2.4,0-4.8,0-7.1,0
		c-0.6,0-0.8,0.2-0.8,0.8c0,4.8,0,9.6,0,14.5c0,0.7,0.3,0.9,0.9,0.9c2.3,0,4.7,0,7,0c0.7,0,1-0.2,1-1
		C145.3,45.4,145.3,43,145.3,40.6z M128.8,16.1c1.1-0.7,1.9-1.4,2.8-1.9c0.9-0.5,0.9-1.7,0.6-1.9c-0.6-0.4-1.6-0.4-2.4-0.5
		c-0.3,0-1,0.2-1,0.4C128.8,13.5,128.8,14.7,128.8,16.1z"
        fill={fill}
      />
      <path
        d="M30,50.4c-6.3,0-12.6,0-18.9,0c-1.7,0-1.9-0.3-1.9-2c0-12,0-24,0-36.1c0-0.2-0.1-0.4,0-0.6
		c0.2-0.4,0.3-1.1,0.7-1.2c0.4-0.1,1,0.1,1.4,0.4c1.5,1.3,2.9,2.7,4.3,4.1C27,25.9,38.4,36.8,49.7,47.7c0.3,0.3,0.7,0.6,1,1
		c0.6,0.7,0.2,1.6-0.7,1.7c-0.3,0.1-0.7,0-1,0C42.6,50.4,36.3,50.4,30,50.4C30,50.4,30,50.4,30,50.4z M11.7,14.4
		c-0.1,0.1-0.2,0.1-0.3,0.2c0,0.3-0.1,0.6-0.1,0.9c0,10.6,0,21.2,0,31.8c0,0.8,0.2,1.1,1.1,1.1c11.2,0,22.4,0,33.6,0
		c0.2,0,0.5-0.1,1-0.2C35.1,36.8,23.4,25.6,11.7,14.4z"
        fill={fill}
      />
      <path
        d="M140.9,21.8c-1.1,0-2.1,0-3.2,0c-0.8,0-0.9-0.5-0.9-1.1c0-0.6,0-1.1,0.9-1.1c2.1,0,4.2,0,6.3,0
		c0.9,0,0.8,0.5,0.8,1.1c0,0.6,0,1.1-0.9,1.1C143,21.8,142,21.8,140.9,21.8z"
        fill={fill}
      />
      <path
        d="M16.4,34.8c0-2.5,0-5,0-7.4c0-0.5,0.3-1.4,0.7-1.5c0.4-0.2,1.2,0.2,1.6,0.6c5,5,9.9,10.1,14.9,15.2
		c0.4,0.4,0.8,1.2,0.6,1.6c-0.2,0.4-1,0.7-1.5,0.7c-4.8,0.1-9.6,0-14.4,0c-1.6,0-1.9-0.3-1.9-1.9C16.4,39.6,16.4,37.2,16.4,34.8
		C16.4,34.8,16.4,34.8,16.4,34.8z M18.7,29.5c-0.1,0.5-0.2,0.7-0.2,1c0,3.4,0,6.8,0,10.3c0,0.8,0.3,1,1.1,1c3.3,0,6.5,0,9.8,0
		c0.3,0,0.6-0.1,1.2-0.1C26.5,37.5,22.7,33.6,18.7,29.5z"
        fill={fill}
      />
    </g>
  </svg>
)

import React, { ReactNode } from "react"
import Ticker from "react-ticker"

export const HeadingTicker = ({ children }: { children: ReactNode }) => (
  <div className="top-0 flex flex-col bg-white z-20 justify-center border-black border-b py-6 md:py-10">
    <Ticker>
      {({ index }) => (
        <div className="pr-10" key={index}>
          <h1 className="text-4xl md:text-5xl uppercase text-left font-ogg leading-loose whitespace-nowrap	">
            {children}
          </h1>
        </div>
      )}
    </Ticker>
  </div>
)

import React, { createContext } from "react"
import { useState } from "react"

const defaultState = {
  open: false,
  toggleMenu: () => {},
}

const SlideMenuContext = createContext(defaultState)

export const SlideMenuProvider = ({ children }: { children: any }) => {
  const [open, setOpen] = useState(false)
  const toggleMenu = () => {
    setOpen(!open)
  }

  return (
    <SlideMenuContext.Provider
      value={{
        open,
        toggleMenu: toggleMenu,
      }}
    >
      {children}
    </SlideMenuContext.Provider>
  )
}

export default SlideMenuContext

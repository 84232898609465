import React from "react"
export const ContactForm = () => {
  return (
    <form
      className="p-4 md:p-8 flex flex-col space-y-4 items-start justify-start max-w-md "
      method="post"
      action="https://getform.io/f/ca1e455e-91d8-40ee-bbdb-bfb5ae261539"
    >
      <div className="form-group w-full flex flex-col gap-6">
        <label className="block text-lg md:text-2xl">Name</label>
        <input
          type="text"
          name="name"
          className="border-b border-black w-full text-lg md:text-2xl focus:outline-none"
        />
      </div>

      <div className="form-group w-full flex flex-col gap-6">
        <label className="block text-lg md:text-2xl">Email</label>
        <input
          type="email"
          name="email"
          className="border-b border-black w-full text-lg md:text-2xl focus:outline-none"
        />
      </div>

      <div className="form-group w-full flex flex-col gap-6">
        <label className="block text-lg md:text-2xl">Message</label>
        <textarea
          name="message"
          className="border-b border-black w-full text-lg md:text-2xl focus:outline-none"
        />
      </div>

      <button className="px-4 py-2 border border-black w-full" type="submit">
        Submit
      </button>
    </form>
  )
}

import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

export const Image = ({
  slice: {
    primary: { image },
  },
}: {
  slice: {
    primary: {
      image: any
    }
  }
}) => {
  const imageObject = getImage(image.gatsbyImageData)
  const height = image.gatsbyImageData.height
  const width = image.gatsbyImageData.width

  const isPotrait = height > width
  const maxWidth = isPotrait ? "max-w-2xl" : "max-w-5xl"

  return (
    <div
      className={`flex justify-center items-center flex-shrink-0 my-8 ${maxWidth} mx-auto`}
    >
      {imageObject && (
        <GatsbyImage
          image={imageObject}
          className="w-full max-w-full mx-auto object-contain"
          alt={image.alt || "image"}
          objectFit="contain"
        />
      )}
    </div>
  )
}

import React, { useContext } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import SlideMenuContext from "../context/SlideMenuContext"
import { iPrismicMenuItem } from "../../types"

const SlideMenu = () => {
  const { open, toggleMenu } = useContext(SlideMenuContext)

  const data = useStaticQuery(graphql`
    query headerQuery {
      prismicMenu(uid: { eq: "navigation" }) {
        uid
        data {
          body {
            ... on PrismicMenuDataBodyMenuItem {
              id
              primary {
                label
                destination
                target_blank
              }
            }
          }
        }
      }
    }
  `)

  const menuItems = data?.prismicMenu?.data?.body

  return (
    <div
      className={`overlay overflow-y-scroll inset-0 bg-white fixed w-screen h-screen ${
        open
          ? "pointer-events-auto opacity-100"
          : "pointer-events-none opacity-0"
      } transition-all`}
    >
      <div
        className={`relative h-full w-full bg-white slide-menu inset-0  transition-all ${
          open && "show"
        }`}
      >
        <div className="flex flex-col h-full justify-center">
          {menuItems.map((i: iPrismicMenuItem, index: number) => (
            <Link
              to={i?.primary?.destination || ""}
              style={{ backgroundColor: determineBackgroundColor(index) }}
              onClick={() => toggleMenu()}
              className="font-ogg flex-grow border-b border-black text-white text-4xl hover:opacity-90 h-20 flex items-center justify-center md:text-lg  uppercase cursor-pointer px-8 py-2 text-right"
            >
              {i.primary.label}
            </Link>
          ))}

          <a
            onClick={() => toggleMenu()}
            style={{ backgroundColor: "red" }}
            className="font-ogg flex-grow border-b border-black text-white text-4xl hover:opacity-90 h-20 flex items-center justify-center md:text-lg  uppercase cursor-pointer px-8 py-2 text-right"
          >
            {open ? "Close" : "Menu"}
          </a>
        </div>
      </div>
    </div>
  )
}

const determineBackgroundColor = (index: number) => {
  if (index === 0) {
    return "orange"
  }

  if (index % 2 === 0) {
    return "blue"
  }

  if (index % 3 === 0) {
    return "green"
  }

  if (index % 3 === 0) {
    return "purple"
  }
  return "red"
}

export default SlideMenu

import React from "react"
import { ContactForm } from "./ContactForm"
import { Image } from "./Image"
import { Paragraph } from "./Paragraph"

const Slices = ({ slices }: { slices: any[] }) => {
  const sliceComponents = slices.map(slice => {
    const key = slice.id

    switch (slice.slice_type) {
      case "image":
        return <Image slice={slice} key={key} />
      case "paragraph_":
      case "paragraph":
        return <Paragraph slice={slice} key={key} />
      case "contact_form":
        return <ContactForm key={key} />
      default:
        return null
    }
  })

  return <>{sliceComponents}</>
}

export default Slices

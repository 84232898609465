import React from "react"
import { RichText, RichTextBlock } from "prismic-reactjs"
import htmlSerializer from "../../helpers/html"

const sizes = {
  small: "Small",
  normal: "Normal",
  medium: "Medium",
  large: "Large",
  xl: "XL",
}

const getClassNames = (size: string) => {
  switch (size) {
    case sizes.small:
      return "slice paragraph small-text text-sm md:text-base leading-loose  max-w-5xl px-4 md:px-8"
    case sizes.normal:
      return "slice paragraph text-lg md:text-2xl"
    case sizes.medium:
      return "slice paragraph text-2xl md:text-3xl lg:text-4xl mx-auto  transition-all large leading-relaxed md:leading-relaxed lg:leading-relaxed p-4 md:p-8"
    case sizes.large:
      return "slice paragraph text-3xl md:text-5xl lg:text-5xl mx-auto  transition-all large leading-relaxed md:leading-relaxed lg:leading-relaxed p-4 md:p-8"
    case sizes.xl:
      return "slice paragraph text-4xl md:text-5xl lg:text-6xl mx-auto  transition-all large leading-relaxed md:leading-relaxed lg:leading-relaxed p-4 md:p-8"
  }
}

export const Paragraph = ({
  slice,
}: {
  slice: {
    primary: {
      size: string
      content: {
        html: string
        raw: RichTextBlock[]
      }
    }
  }
}) => {
  const size = slice.primary.size
  let className = getClassNames(size)

  return (
    <div className="text-left text-lg ">
      {[sizes.small, sizes.large, sizes.xl].includes(size) ? (
        <div
          className={className}
          dangerouslySetInnerHTML={{ __html: slice.primary.content.html }}
        />
      ) : (
        <div className={className}>
          <RichText
            render={slice.primary.content.raw}
            htmlSerializer={htmlSerializer}
          />
        </div>
      )}
    </div>
  )
}

import { componentResolverFromMap } from "gatsby-plugin-prismic-previews"
import Page from "./src/templates/page"
import Pdf from "./src/templates/pdf"
import Project from "./src/templates/project"

export const componentResolver = componentResolverFromMap({
  page: Page,
  pdg: Pdf,
  project: Project,
})

import { Link } from "gatsby"
import React from "react"
import { AshCircle } from "./AshCircle"

export const Footer = () => {
  return (
    <footer className="w-full p-4 md:p-8 border-t border-black flex justify-between">
      <div className="flex gap-x-8 flex-wrap">
        <div className="flex flex-col gap-4 flex-shrink-0">
          <Link to="/about" className="font-ogg md:text-lg">
            About
          </Link>
          <Link to="/contact" className="font-ogg md:text-lg">
            Contact
          </Link>
          <Link
            target="_blank"
            to="https://www.linkedin.com/in/ash-compton-ma-lmft-emdr-certified-4240a9185"
            className="font-ogg md:text-lg"
          >
            CV
          </Link>
        </div>
        <div className="flex flex-col gap-4 flex-shrink-0 ">
          <Link to="/work" className="font-ogg md:text-lg">
            Work
          </Link>
          <Link to="/work/all" className="font-ogg md:text-lg">
            Select Projects
          </Link>
          <Link to="/clippings" className="font-ogg md:text-lg">
            Clippings
          </Link>
        </div>
      </div>

      <div className="w-auto">
        <AshCircle />
      </div>
    </footer>
  )
}

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({
  description,
  lang,
  title,
}: {
  description?: string
  lang?: string
  title: string
  meta?: any
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `${defaultTitle} | %s` : undefined}
    >
      <meta property="og:title" content={`Ash North Compton | ${title}`} />
      <meta property="og:description" content={metaDescription} />
      <meta name="description" content={metaDescription} />
      <meta property="og:image" content={"/og.png"} />
      <meta property="og:type" content="website" />
    </Helmet>
  )
}

export default Seo

import React from "react"
import { Elements } from "prismic-reactjs"
import { Arch, Essays, Hands } from "../components/icons"
import { linkResolver } from "../../linkResolver"

// -- Function to add unique key to props
const propsWithUniqueKey = function (props: any, key: string) {
  return Object.assign(props || {}, { key })
}

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
const htmlSerializer = function (
  type: string,
  element: any,
  content: any,
  children: any,
  key: string
) {
  var props = {}
  switch (type) {
    // Add a class to paragraph elements
    case Elements.paragraph:
      props = { className: "text-lg md:text-2xl py-4 px-4 md:px-8 w-full" }
      return React.createElement("p", propsWithUniqueKey(props, key), children)

    // Don't wrap images in a <p> tag
    case Elements.image:
      props = { src: element.url, alt: element.alt || "" }
      return React.createElement("img", propsWithUniqueKey(props, key))

    // Add a class to hyperlinks
    case Elements.hyperlink:
      const isLinkExternal = !!(element.data.link_type === "Web")

      const targetAttr = element.data.target
        ? { target: element.data.target }
        : isLinkExternal
        ? {
            target: "_blank",
          }
        : {}

      const relAttr = element.data.target ? { rel: "noopener" } : {}

      const href =
        element.data.link_type === "Document"
          ? linkResolver(element.data)
          : element.data.url

      props = Object.assign(
        {
          className: "link-class",
          href,
        },
        targetAttr,
        relAttr
      )
      return React.createElement("a", propsWithUniqueKey(props, key), children)
    case Elements.heading2:
      props = {
        className:
          "text-5xl py-4 px-4 md:px-8 border-b border-black text-white",
        style: { background: "red" },
      }
      return React.createElement("h2", propsWithUniqueKey(props, key), children)
    case Elements.heading3:
      props = {
        className:
          "text-4xl py-4 px-4 md:px-8 border-b border-black text-white",
      }

      if (/^Design/i.test(children?.[0])) {
        return (
          <h3
            style={{ backgroundColor: "green" }}
            className="border-black border-b  text-3xl md:text-4xl text-white text-center md:text-left py-2 px-4 md:px-8 flex items-center justify-between flex-col-reverse md:flex-row"
          >
            {children}
            <Arch fill="white" style={{ width: "12rem", height: "5rem" }} />
          </h3>
        )
      }

      if (/^Psychotherapist/i.test(children?.[0])) {
        return (
          <h3
            style={{ backgroundColor: "red" }}
            className="border-black border-b  text-3xl md:text-4xl text-white text-center md:text-left py-2 px-4 md:px-8 flex items-center justify-between flex-col-reverse md:flex-row"
          >
            {children}

            <img
              className="w-48 h-20 object-contain"
              src="/essays.svg"
              alt="essays icon"
            />
          </h3>
        )
      }

      if (/^Art/i.test(children?.[0])) {
        return (
          <h3
            style={{ backgroundColor: "blue" }}
            className="border-black border-b text-3xl md:text-4xl text-white text-center md:text-left py-2 px-4 md:px-8 flex items-center justify-between flex-col-reverse md:flex-row"
          >
            {children}
            <Hands fill="white" style={{ width: "12rem", height: "5rem" }} />
          </h3>
        )
      }
      return React.createElement("h3", propsWithUniqueKey(props, key), children)
    case Elements.heading4:
      props = {
        className:
          "text-3xl py-4 px-4 md:px-8 border-b border-black text-white",
        style: { background: "green" },
      }
      return React.createElement("h4", propsWithUniqueKey(props, key), children)
    case Elements.heading5:
      props = {
        className:
          "text-3xl py-4 px-4 md:px-8 border-y-1 border-black text-white",
        style: { background: "blue" },
      }
      return React.createElement("h3", propsWithUniqueKey(props, key), children)
    // Return null to stick with the default behavior
    default:
      return null
  }
}

export default htmlSerializer

export const linkResolver = (doc: any) => {
  switch (doc.type) {
    case "pdf":
      return `/pdfs/${doc.uid}`
    case "page":
      return `/${doc.uid}`
    case "project":
      return `/work/${doc.uid}`
    default:
      return "/"
  }
}

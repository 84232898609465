import "./src/css/index.css"
import React from "react"
import { SlideMenuProvider } from "./src/context/SlideMenuContext"
import { AnimatePresence } from "framer-motion"
import { WrapRootElementBrowserArgs } from "gatsby"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "./linkResolver"
import { componentResolver } from "./componentResolver"

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME || "",
        linkResolver,
        componentResolver,
      },
    ]}
  >
    <AnimatePresence>
      <SlideMenuProvider>{element}</SlideMenuProvider>
    </AnimatePresence>
  </PrismicPreviewProvider>
)

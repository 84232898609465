import { Arch, Climate, Essays } from "./icons"
import React from "react"

type CategoryIconProps = {
  categoryUid: string
  fill: string
  style: React.CSSProperties
}

export const CategoryIcon = ({
  categoryUid,
  fill = "black",
  style,
}: CategoryIconProps) => {
  return (
    <>
      {categoryUid === "sustainable-interior-architecture" && (
        <Arch fill={fill} style={style} />
      )}
      {categoryUid === "essays" && <Essays fill={fill} style={style} />}
      {categoryUid === "climate-change-adaptation" && (
        <Climate fill={fill} style={style} />
      )}
    </>
  )
}

import React, { useContext } from "react"
import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"
import SlideMenuContext from "../context/SlideMenuContext"
import { iPrismicMenuItem } from "../../types"

const Header = () => {
  const { toggleMenu, open } = useContext(SlideMenuContext)

  const data = useStaticQuery(graphql`
    query headerQuery {
      prismicMenu(uid: { eq: "navigation" }) {
        uid
        data {
          body {
            ... on PrismicMenuDataBodyMenuItem {
              id
              primary {
                label
                destination
                target_blank
              }
            }
          }
        }
      }
    }
  `)

  const menuItems = data?.prismicMenu?.data?.body
  console.log(menuItems)
  return (
    <header className="border-b border-black bg-white w-full flex flex-row justify-between p-8 md:fixed items-center bg-no-repeat bg-top z-50 h-20">
      <Link to="/" className="text-2xl md:text-3xl font-ogg">
        Ash North Compton
      </Link>
      <div className=" gap-x-8 hidden md:flex">
        {menuItems.map((i: iPrismicMenuItem) => (
          <Link
            to={i?.primary?.destination || ""}
            activeClassName="underline"
            partiallyActive
            className="hover:underline md:text-lg font-ogg"
          >
            {i?.primary.label}
          </Link>
        ))}
      </div>
      <div className="flex md:hidden">
        <span
          role="button"
          onClick={() => toggleMenu()}
          className="hover:underline  md:text-lg font-ogg uppercase cursor-pointer"
        >
          {open ? "Close" : "Menu"}
        </span>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

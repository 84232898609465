import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import { Viewer, SpecialZoomLevel, Worker } from "@react-pdf-viewer/core"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"

type PdfProps = {
  data: {
    prismicPdf: {
      id: string
      uid: string
      data: {
        file: {
          url: string
        }
      }
    }
  }
}

const Pdf = ({ data }: PdfProps) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: defaultTabs => [
      defaultTabs[0], // Thumbnails tab
    ],
  })

  return (
    <Layout>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
        <section id="review-pdf" className="max-w-5xl p-4 md:p-8 mx-auto">
          <Viewer
            fileUrl={data.prismicPdf.data.file.url}
            plugins={[defaultLayoutPluginInstance]}
          />
        </section>
      </Worker>
    </Layout>
  )
}

export default Pdf

export const query = graphql`
  query Pdf($id: String) {
    prismicPdf(id: { eq: $id }) {
      _previewable
      id
      uid

      data {
        file {
          url
        }
      }
    }
  }
`
